import axios from 'axios';
import Constants from './constants';
import JWT from 'expo-jwt';

(() => {
  axios.interceptors.request.use((config) => {
    const coverKey = localStorage.getItem('coverKey');
    const key = `${process.env.REACT_APP_FORMS_JWTKEY_32BIT}`;
    
    const payload = {
      coverKey: coverKey,
      exp: Math.floor(Date.now() / 1000) + (6 * 60 * 60)
    };
    
    const jwtToken = JWT.encode(payload, key, { algorithm: 'HS256' });
    
    config.headers[Constants.headerKey] = Constants.headerValue;
    config.headers['Authorization'] = `Bearer ${jwtToken}`;
    
    return config;
  });
})();